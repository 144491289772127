import Grid from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import TitleCard from "../TitleCard";
import ServiceCard from "./ServiceCard";
import { SERVICES_DATA } from "./services.data.js";

const Services = () => {
  return (
    <Stack>
      <TitleCard textType='h5' text='servicesTitle' />
      <Grid with='100%' container>
        {SERVICES_DATA.map((service) => (
          <Grid
            key={service.icon}
            item
            display='flex'
            justifyContent='center'
            textAlign='center'
            p='2rem'
            sm={6}
            md={4}
            lg={2}
          >
            <ServiceCard
              icon={service.icon}
              text={service.text}
              englishIcon={service.englishIcon}
              iconExtension={service.iconExtension}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Services;
