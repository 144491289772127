const frenchReviews = [
  // GOOGLE REVIEWS
  {
    author_name: "PHILIPPE MORAND",
    author_url:
      "https://www.google.com/maps/contrib/114472659869150736507/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTtfwta7UGFtVmxD3KKBdD8djZ5z2HQ6FQ9OvVm67=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "il y a un mois",
    text: "Facile d’accès 24h/24h, pas d’attente avec borne automatique et CB, vous payez la 1ère nuit, si vous restez plusieurs jours vous paierez le nombre de nuit totale en sortant, très simple.\nLe lieu est très calme, avec le bout du lac, et à 200m de la voie verte: Annecy à Albertville et plus.\nVous êtes a la campagne avec vue sur les Alpes magnifiques.",
    time: 1683205412,
    translated: false,
  },
  {
    author_name: "Catherine Renault",
    author_url:
      "https://www.google.com/maps/contrib/104405658803984842954/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/ACg8ocLFlMwozeCN2uOUKbvA1mJv30zH1VcaekagU6JcUoSpG6Ys6g=w90-h90-p-rp-mo-ba2-br100",
    rating: 5,
    relative_time_description: "il y a 9 mois",
    text: "Nous y avons passé 14 jours. Endroit très propre emplacement grand .commodités et services très bien et surtout très calme. Le propriétaire est toujours disponible. A proximité des bus pour aller à annecy. Ballades sur le lac en bateaux, restaurants.... je recommande.",
    time: 1661242698,
    translated: false,
  },
  {
    author_name: "Matou Lhermitte",
    author_url:
      "https://www.google.com/maps/contrib/118019149942128950824/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a-/ALV-UjXTKgXCGn-_P_vXeRKHymvkc1fB5cf0BKKQasc9cPsOQiFmGcNb=w90-h90-p-rp-mo-ba4-br100",
    rating: 5,
    relative_time_description: "il y a 10 mois",
    text: "Nous avons passé quelques jours dans cette air ! Propre , rien à redire ! Prix plus que raisonnable, quand on voit les tarifs du secteur ! Personnellement, j’aurais aimé une petite douche !",
    time: 1659116945,
    translated: false,
  },
  {
    author_name: "Chrt Mrt",
    author_url:
      "https://www.google.com/maps/contrib/108470089312769266018/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a-/AD_cMMQQipcVkb2DLtn-txTBUhU76zbtSDAn_Jr_xAJxPw=s128-c0x00000000-cc-rp-mo-ba4",
    rating: 5,
    relative_time_description: "il y a 10 mois",
    text: "Jolie aire avec tous les services, calme et dans la verdure.\nVue magnifique sur les montagnes qui entourent le lac.\nProche de la piste cyclable pour aller à Annecy.\nPropriétaire sympathique.\nNous y reviendrons sûrement.",
    time: 1659116945,
    translated: false,
  },

  // PARK4NIGHT REVIEWS
  {
    author_name: "veronike01",
    author_url: "https://park4night.com/fr/user/veronike01",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "Parfait ! Calme, propre et idéal pour rejoindre le Lac d’Annecy en vélo et visiter la réserve naturelle du bout du Lac! Pistes cyclables à proximité ! Nous reviendrons, c’est sur !",
    time: 1776758040,
  },
  {
    author_name: "nabe13",
    author_url: "https://park4night.com/fr/user/nabe13",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "tout était parfait, les emplacements, la proximité de la piste cyclable et du lac, les services bien étudiés(vidange, eau etc..) surtout continuez à bien entretenir votre aire.",
    time: 1776758037,
  },
  {
    author_name: "lesjoemi",
    author_url: "https://park4night.com/fr/user/lesjoemi",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_p.png?v=b30ff3b",
    rating: 4,
    text: "Aire très agréable avec tous les services, nous avons passé 2 superbes nuits. Plage et voie verte pas loin. Dommage, certains emplacements sont un peu pentus. Tarif correct.",
    time: 1776758038,
  },
  {
    author_name: "Fredwhite",
    author_url: "https://park4night.com/fr/user/Fredwhite",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=e89eb7c",
    rating: 5,
    relative_time_description: "il y a 2 semaines",
    text: "Endroit exceptionnel je recommande fortement. Très propre, calme. Nous étions en Calif, donc très appréciable d'avoir un toilette à notre disposition et surtout propre. Nous reviendrons .",
    time: 1685118409,
    translated: false,
  },
  {
    author_name: "mayork",
    author_url: "https://park4night.com/fr/user/mayork",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "Nous sommes actuellement sur cette aire privée. Ne vous embêtez pas à chercher une aire sur Annecy, vous ne trouverez pas mieux sur l’agglomération. Les camping-cars ne sont pas les bienvenus à Annecy. On vous renvoie sur les campings qui sont hors de prix. Les propriétaires sont très accueillants et serviables. Ils vous dépannent au besoin. Je ne peux que vous encourager à vous installer ici. Mériterait une meilleure signalisation. Il faut passer sous un pont sur lequel passe la voie verte, il est étroit mais un tracteur passe, puis il faut continuer 200 à 300 m",
    time: 1776758039,
  },
  {
    author_name: "poupy26",
    author_url: "https://park4night.com/fr/user/poupy26",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 4,
    text: "Merci au propriétaire pour cette aire très agréable avec tous services y compris Wi-Fi à proximité d Annecy soit en scooter soit a vélo et la plage à 10 minutes à pieds merci de préserver cet endroit quand vous y séjournez prix très correct pour la région",
    time: 1776758040,
  },
];

export default frenchReviews;
