import { useSelector } from "react-redux";
import { Stack } from "@mui/system";

import Text from "../Text";

const ServiceCard = ({
  icon,
  text,
  englishIcon = undefined,
  iconExtension = "svg",
}) => {
  const language = useSelector((store) => store.LANGUAGE.language);

  return (
    <Stack
      direction='column'
      alignItems='center'
      gap='1rem'
      data-aos='zoom-out'
      data-aos-mirror='true'
      data-aos-once='true'
    >
      <img
        src={`./picto/${
          language === "english" && englishIcon ? englishIcon : icon
        }.${iconExtension}`}
        alt={icon}
        width='200rem'
        height='200rem'
      />
      <Text style={{ width: "fit-content" }} textName={text} />
    </Stack>
  );
};

export default ServiceCard;
