import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import frenchTexts from "../frenchTexts";
import englishTexts from "../englishTexts";

const Text = ({ textName, variant, textAlign, sx, color, dataAos }) => {
  const language = useSelector((store) => store.LANGUAGE.language);

  let texts = frenchTexts;

  language &&
    (language === "english" ? (texts = englishTexts) : (texts = frenchTexts));

  let content = texts.find((e) => e.name === textName);

  !content && (content = { text: "" });

  return (
    <Typography
      variant={variant}
      textAlign={textAlign}
      sx={sx}
      color={color}
      data-aos={dataAos}
      data-aos-mirror='true'
      data-aos-once='true'
      dangerouslySetInnerHTML={{
        __html: content.text,
      }}
    />
  );
};

export default Text;
