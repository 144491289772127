import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Text from "./Text";

const TarifsCard = ({ info }) => {
  return (
    <Stack
      sx={{
        width: "14rem",
        backgroundColor: "white",
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        padding: "0.5rem",
      }}
    >
      <Stack
        sx={{
          width: "99%",
          backgroundColor: "primary.main",
          borderRadius: "5px",
        }}
      >
        <Text textName={info.title} color='white' sx={{ fontWeight: "bold" }} />
      </Stack>
      <Stack gap='0.5rem'>
        <Text textName={info.firstPeriod} variant='h6'></Text>
        <Text
          textName={info.price}
          variant='h4'
          sx={{ fontWeight: "bold" }}
        ></Text>
      </Stack>
    </Stack>
  );
};

export default TarifsCard;
