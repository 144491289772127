import { Stack, Box } from "@mui/system";
import Text from "./Text";
import TarifsCard from "./TarifsCard";

const Tarifs = () => {
  const prices = [
    {
      firstPeriod: "tarifsLowDates",
      price: "tarifsLowPrice",
      title: "lowSeason",
    },
    {
      firstPeriod: "tarifsShoulderDates",
      price: "tarifsShoulderPrice",
      title: "shoulderSeason",
    },
    {
      firstPeriod: "tarifsPeakDates",
      price: "tarifsPeakPrice",
      title: "peakSeason",
    },
  ];

  return (
    <Stack m={"1rem"}>
      <Stack
        sx={{
          width: "90%",
          backgroundColor: "primary.main",
          p: { xs: "0.5rem 0", sm: "1rem 2rem" },
          gap: "1rem",
          margin: "auto",
          borderRadius: "20px",
          textAlign: "center",
        }}
      >
        <Stack>
          <Text textName='tarifsParking' variant='h4' color='white' />
          <Text textName='tarifsParkingSubtitle' variant='h6' color='white' />
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          {prices.map((price) => (
            <TarifsCard key={price.title} info={price} />
          ))}
        </Stack>
        <Text textName='touristTax' color='white' variant='subtitle2' />
      </Stack>
    </Stack>
  );
};

export default Tarifs;
