export const SERVICES_DATA = [
  {
    text: "plugService",
    icon: "plug",
    iconExtension: "png",
  },
  {
    text: "wifiService",
    icon: "wifi",
    iconExtension: "svg",
  },
  {
    text: "waterService",
    icon: "water",
    iconExtension: "svg",
  },
  {
    text: "capacityService",
    englishIcon: "capacity-english",
    icon: "capacity",
    iconExtension: "png",
  },
  {
    text: "cardService",
    icon: "credit-card",
    iconExtension: "svg",
  },
  {
    text: "languageService",
    icon: "language",
    iconExtension: "png",
  },
  {
    text: "recyclingService",
    icon: "recycling",
    iconExtension: "png",
  },
  {
    text: "drainingService",
    icon: "draining",
    iconExtension: "svg",
  },
  {
    text: "toiletService",
    icon: "toilet",
    iconExtension: "png",
  },
  {
    text: "sizeRestriction",
    icon: "size",
    iconExtension: "png",
  },
  {
    text: "trailerRestriction",
    icon: "trailer",
    iconExtension: "png",
  },
  {
    text: "carRestriction",
    icon: "car",
    iconExtension: "png",
  },
];
