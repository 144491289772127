const englishReviews = [
  // GOOGLE REVIEWS
  {
    author_name: "PHILIPPE MORAND",
    author_url:
      "https://www.google.com/maps/contrib/114472659869150736507/reviews",
    language: "en",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTtfwta7UGFtVmxD3KKBdD8djZ5z2HQ6FQ9OvVm67=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "a month ago",
    text: "Easy to access 24 hours a day, no waiting with automatic terminal and credit card, you pay the 1st night, if you stay several days you will pay the total number of nights when you leave, very simple.\nAround 15€/24h depending on the season, water, electricity and tourist taxes included.\nThe place is very quiet, with the end of the lake, and 200m from the greenway: Annecy to Albertville and more.\nYou are in the countryside with a view of the magnificent Alps.",
    time: 1683205412,
    translated: true,
  },
  {
    author_name: "Catherine Renault",
    author_url:
      "https://www.google.com/maps/contrib/104405658803984842954/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/ACg8ocLFlMwozeCN2uOUKbvA1mJv30zH1VcaekagU6JcUoSpG6Ys6g=w90-h90-p-rp-mo-ba2-br100",
    rating: 5,
    relative_time_description: "il y a 9 mois",
    text: "We spent 14 days there. Very clean, great location. Very good amenities and services and, above all, very quiet. The owner is always available. Close to buses to Annecy. Boat trips on the lake, restaurants.... I recommend.",
    time: 1661242698,
    translated: false,
  },

  {
    author_name: "Matou Lhermitte",
    author_url:
      "https://www.google.com/maps/contrib/118019149942128950824/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a-/ALV-UjXTKgXCGn-_P_vXeRKHymvkc1fB5cf0BKKQasc9cPsOQiFmGcNb=w90-h90-p-rp-mo-ba4-br100",
    rating: 5,
    relative_time_description: "il y a 10 mois",
    text: "We spent a few days in this air! Clean, nothing to complain about! Price more than reasonable, when you see the rates in the area! Personally, I would have liked a small shower!",
    time: 1659116945,
    translated: false,
  },
  {
    author_name: "Chrt Mrt",
    author_url:
      "https://www.google.com/maps/contrib/108470089312769266018/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a-/AD_cMMQQipcVkb2DLtn-txTBUhU76zbtSDAn_Jr_xAJxPw=s128-c0x00000000-cc-rp-mo-ba4",
    rating: 5,
    relative_time_description: "il y a 10 mois",
    text: "Nice area with all services, quiet and surrounded by greenery.\nMagnificent view of the mountains around the lake.\nClose to the bike path to Annecy.\nFriendly owner.\nWe will definitely come back.",
    time: 1659116945,
    translated: false,
  },

  // PARK4NIGHT REVIEWS
  {
    author_name: "veronike01",
    author_url: "https://park4night.com/fr/user/veronike01",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "Just perfect! Quiet, clean and ideal for cycling to Lake Annecy and visiting the nature reserve at the end of the lake! Cycle paths nearby! We'll be back for sure!",
    time: 1776758040,
  },
  {
    author_name: "nabe13",
    author_url: "https://park4night.com/en/user/nabe13",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "everything was perfect, the pitches, the proximity to the bike path and the lake, the well-designed services (emptying, water, etc.) above all, continue to maintain your area well.",
    time: 1776758037,
  },
  {
    author_name: "Fredwhite",
    author_url: "https://park4night.com/fr/user/Fredwhite",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=e89eb7c",
    rating: 5,
    relative_time_description: "il y a 2 semaines",
    text: "Exceptional location, highly recommended. Very clean, quiet. We were in Calif, so it was very nice to have a toilet at our disposal and especially clean. We'll be back .",
    time: 1685118409,
    translated: false,
  },
  {
    author_name: "lesjoemi",
    author_url: "https://park4night.com/en/user/lesjoemi",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_p.png?v=b30ff3b",
    rating: 4,
    text: "Very pleasant area with all the services, we spent 2 superb nights. Beach and greenway not far. Too bad, some pitches are a little steep. Right price.",
    time: 1776758038,
  },
  {
    author_name: "LADGUY",
    author_url: "https://park4night.com/en/user/LADGUY",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_gv.png?v=b30ff3b",
    rating: 5,
    text: "very nice place to stay very quiet during the day and night as its just off the main road. manager very welcoming. Area by gate to fill and empty water and waste. also bins provided. would stay again.",
    time: 1776758039,
  },
  {
    author_name: "TomBrookes",
    author_url: "https://park4night.com/en/user/TomBrookes",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "A fantastic Aire in a fantastic spot. Excellent facilities.",
    time: 1776758040,
  },
];

export default englishReviews;
