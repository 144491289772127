const englishTexts = [
  // ABOUT
  {
    name: "aboutTitle",
    text: "L'Hirondelle - motorhome park welcomes you",
  },
  {
    name: "aboutContent",
    text: `Created in the early 2000s by my parents, who are keen on travelling by motorhome, the the area has evolved each year to allow you to spend a pleasant to spend a pleasant and relaxing moment, while remaining in the simplicity and conviviality.<br />
    All pitches are on grass and relatively flat, sunny and delimited with a great view on the mountains`,
  },
  // TARIFS
  {
    name: "tarifsTitle",
    text: "TARIFS",
  },
  {
    name: "tarifsTitle",
    text: "TARIFS",
  },
  {
    name: "tarifsParking",
    text: "24 HOURS FLATE RATE",
  },
  {
    name: "tarifsParkingSubtitle",
    text: "SERVICES AND TOURIST TAX FOR 2 PERSONS INCLUDED",
  },
  {
    name: "lowSeason",
    text: "Low Season",
  },
  {
    name: "tarifsLowDates",
    text: "04/01 - 05/31 <br />10/01 - 11/15",
  },
  {
    name: "tarifsLowPrice",
    text: "20€",
  },
  {
    name: "shoulderSeason",
    text: "Shoulder Season",
  },
  {
    name: "tarifsShoulderDates",
    text: "06/01 - 06/30 <br />09/01 - 09/30",
  },
  {
    name: "tarifsShoulderPrice",
    text: "22€",
  },
  {
    name: "peakSeason",
    text: "Peak Season",
  },
  {
    name: "tarifsPeakDates",
    text: "07/01 - 08/31<br /><br />",
  },
  {
    name: "tarifsPeakPrice",
    text: "24€",
  },
  {
    name: "touristTax",
    text: "Tourist tax 60cts per person per day",
  },
  // SERVICES
  {
    name: "servicesTitle",
    text: "SERVICES",
  },
  {
    name: "plugService",
    text: `All pitches have a 6A (1380 watts) power socket, no air conditioning or other energy consuming appliances possible, only for charging your cell.`,
  },
  {
    name: "wifiService",
    text: `You will have access to wifi with a very good speed, the access code will be given to you on your ticket at the entrance`,
  },
  {
    name: "waterService",
    text: `One hose on each side for easy filling without having to manoeuvre`,
  },
  {
    name: "capacityService",
    text: `The area offers 36 motorhome parking spots`,
  },
  {
    name: "cardService",
    text: `Entry and exit to the area is via an automatic blue card terminal. You will find a clear explanation panel to allow you to enter the area`,
  },
  {
    name: "languageService",
    text: "Language selection available at the entrance terminal to make your arrival easier",
  },
  {
    name: "recyclingService",
    text: `The area has selective sorting containers as well as a composter`,
  },
  {
    name: "drainingService",
    text: `A tank for emptying chemical toilets with flushing and a flushing tank`,
  },
  {
    name: "toiletService",
    text: `Public toilets are available on-site for your convenience`,
  },
  {
    name: "sizeRestriction",
    text: `Motorhome length limited to 8 meters`,
  },
  {
    name: "trailerRestriction",
    text: `Trailers carrying a car are not allowed (Small trailers for motorbikes are welcome)`,
  },
  {
    name: "carRestriction",
    text: `Area reserved for motorhomes only (Tents, including roof tents, are not allowed)`,
  },
  // NEARBY
  {
    name: "nearbyTitle",
    text: "NEARBY",
  },
  {
    name: "jardinTailleferTitle",
    text: "Jardin du Taillefer",
  },
  {
    name: "jardinTailleferContent",
    text: `Located in the middle of the family farmland, you will find on the spot twice a week a sale of organic vegetables (Monday and Thursday from 4.30 pm to 7.30 pm) as well as a 100% local vegetarian restaurant <br /> <a href="https://www.jardins-du-taillefer.fr" target='_blank' rel='noopener noreferrer'>See the website</a>`,
  },
  {
    name: "hikingTitle",
    text: "Hiking",
  },
  {
    name: "hikingContent",
    text: `Many walks starting from the area in order to get some height and admire the lake or to discover the nature reserve at the end of the lake`,
  },
  {
    name: "bikeTitle",
    text: "Bike Path",
  },
  {
    name: "bikeContent",
    text: `The area is very quiet: close to the Annecy-Albertville cycle path and  from the first beach on Annecy lake`,
  },
  // REVIEW
  {
    name: "reviewTitle",
    text: "YOUR REVIEWS",
  },
  {
    name: "seeMoreReviews",
    text: "SEE MORE REVIEWS",
  },
  // FOOTER
  {
    name: "signatureFooter",
    text: `<a href="https://sanb.fr" target='_blank' rel='noopener noreferrer'>Website by Sandro Brignoli 💭</a>`,
  },
];

export default englishTexts;
